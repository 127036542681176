import { Separator, Text } from "@packages/ui";
import { useTranslations } from "@services/strings";
import Image from "next/image";
import Link from "next/link";
import FacebookIcon from "@public/icons/facebook.svg";
import InstagramIcon from "@public/icons/instagram.svg";
import LinkedinIcon from "@public/icons/linkedin.svg";

export const Footer = () => {
  const { t } = useTranslations();
  return (
    <footer className="bg-slate-50 max-w-none container py-10 flex justify-center">
      <div className="max-w-6xl w-full flex flex-col gap-8">
        <div className="flex flex-col gap-20">
          <div className="flex flex-row justify-between gap-10">
            <Image
              src={"/logo.svg"}
              height={27}
              width={77}
              alt={"mnzl logo"}
              className="self-start"
            />
            <ul className="flex flex-col gap-2">
              <li className="whitespace-nowrap">
                <Link
                  href={"/static/contact-us/"}
                  className="text-sm text-slate-500"
                >
                  {t("footer.links.contact")}
                </Link>
              </li>
              <li className="whitespace-nowrap">
                <a
                  href={
                    "https://mnzl.notion.site/Careers-543d2c1576ce4da2a91a626c98658158"
                  }
                  target={"_blank"}
                  className="text-sm text-slate-500"
                >
                  {t("footer.links.careers")}
                </a>
              </li>
              <li className="whitespace-nowrap">
                <Link
                  href={"/static/cookie-policy/"}
                  className="text-sm text-slate-500"
                >
                  {t("footer.links.cookie-policy")}
                </Link>
              </li>
              <li className="whitespace-nowrap">
                <Link
                  href={"/static/privacy-policy/"}
                  className="text-sm text-slate-500"
                >
                  {t("footer.links.privacy-policy")}
                </Link>
              </li>
            </ul>
          </div>

          <div className={"flex flex-row gap-10 self-center"}>
            <a
              target={"_blank"}
              href={"https://www.facebook.com/profile.php?id=100094671844602"}
            >
              <Image src={FacebookIcon} alt="facebook" />
            </a>
            <a target={"_blank"} href={"https://www.instagram.com/mymnzl/"}>
              <Image src={InstagramIcon} alt="instagram" />
            </a>
            <a
              target={"_blank"}
              href={
                "https://www.linkedin.com/company/101493505/admin/feed/posts/"
              }
            >
              <Image src={LinkedinIcon} alt="linkedin" />
            </a>
          </div>
        </div>
        <Separator />
        <Text color={"grey60"} fontSize={12}>
          {t("footer.rights", { year: new Date().getFullYear() })}
        </Text>
      </div>
    </footer>
  );
};
