import { Color, absoluteCenter, sprinkles } from "@packages/ui";
import Image from "next/image";
import { LanguagePicker } from "@components/LanguagePicker";
import Link from "next/link";
import { paths } from "@utils/paths";

export const Header = ({ background = "white" }: { background?: Color }) => {
  return (
    <header
      className={sprinkles({
        height: 80,
        background,
        position: "sticky",
      })}
      style={{ top: 0, zIndex: 100 }}
    >
      <nav
        className={sprinkles({
          marginX: 24,
          position: "relative",
          display: "flex",
          alignItems: "center",
          height: "100%",
        })}
      >
        <div>
          <Link href={paths.home} className={absoluteCenter}>
            <Image src={"/logo.svg"} height={27} width={77} alt={"mnzl logo"} />
          </Link>
          <LanguagePicker />
        </div>
      </nav>
    </header>
  );
};
